<template>
  <div class="px-4 pt-10">
    <!--van-cell-group class="mb-10">
      <van-field
        label="手机号"
        placeholder="请输入手机号"
        v-model="form.mob"
        type="tel"
        maxlength="11"
      />
      <van-field
        label="密码"
        placeholder="请输入密码"
        v-model="form.password"
        type="password"
      />
    </van-cell-group>
    <van-button @click="submit" type="primary" block :disabled="disabled"
      >登录</van-button
    >
    <van-button class="mt-4" type="primary" plain block @click="register"
      >新用户注册</van-button
    >
    <van-button class="mt-4" type="primary" plain block @click="order"
      >跳过登录，直接点餐</van-button
    -->
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { login } from '@/api'
import { getOpenId, getOpenIdByCode, setOpenId } from '@/api'

export default {
  data() {
    return {
      form: {
        mob: '',
        password: ''
      }
    }
  },

  methods: {
    async submit() {
      try {
        await login(this.form)
        await Dialog.alert({
          message: '登录成功！'
        })
        this.$router.replace({
          name: 'orderType'
        })
      } catch (err) {
        Toast(err.message)
      }
    },

    order() {
      this.$router.replace({
        name: 'menu'
      })
    },

    register() {
      this.$router.push({
        name: 'register'
      })
    }
  },

  computed: {
    disabled() {
      return this.form.mob.length < 11
    }
  },

  async mounted() {
    const code = this.$route.query.code
    const openId = getOpenId()
    if (code) {
      getOpenIdByCode({
        code
      })
        .then(res => {
          setOpenId(res.OpenID)
          this.$router.push({
            name: 'menu'
          })
        })
        .catch(err => {
          Toast({
            message: err
          })
        })
      return
    }
    if (!openId) {
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
        window.CONFIG.appId
      }&redirect_uri=${encodeURIComponent(
        location.href
      )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
    } else {
      this.$router.push({
        name: 'menu'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>